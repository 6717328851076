import logger from 'OK/logger';
import { ajax, prepareForPost, updateBlocks } from 'OK/utils/vanilla';
import { traverseParents, parent, firstByClass, outerSize } from 'OK/utils/dom';
import Delegate from 'OK/utils/delegate';

var blockId = 'ShortcutMenuReact';
var activeInstance = null;

// create block element one time (after loading module) when elements with shortcut menus are really on the page
var block = document.createElement('div');
block.id = 'hook_Block_' + blockId;
document.body.appendChild(block);

OK.hookModel.captureBlockHook('body', block);

// Gif добавлен
block.addEventListener('change', function () {
    if (activeInstance && activeInstance.visible()) {
        activeInstance.positingElement();
    }
});

// Мапа ссылок ReactComponents для ExpressReactionPopup
var reactComponents = {};

/**
 * копия ШМ для реакций с костылями
 */

class ShortcutMenuReact {
    constructor(hookId) {
        this.hookId = hookId;
        this.stopClick = true;
        this.ajaxRequest = null;
        this.showTimeout = null;
        this.hideTimeout = null; // timeout ids for tracking open/close, delays and cancel if needed
        this.menuElement = null;
        this.cacheable = true;
        this.loaded = false;
        this.showOnClick = true;
        this._hoverIn = false;
    }

    static getActive() {
        return activeInstance;
    }

    static setReactComponent(blockHookId, reactComponent) {
        reactComponents[blockHookId] = reactComponent;
    }

    static removeReactComponent(blockHookId) {
        delete reactComponents[blockHookId];
    }

    static getReactComponent(blockHookId) {
        return reactComponents[blockHookId];
    }

    /**
     * Activate hook.
     * Add all event listeners to element that previous from hook element
     * @param {HTMLElement} element hook element
     */
    activate(element) {
        this.element = element;
        this.holder = this.getHolder();
        this.blockId = this.element.getAttribute('data-blockid');
        this.block = document.getElementById('hook_Block_' + this.blockId);
        this.inplace = this.element.getAttribute('data-inplace');
        this.loaded = this.element.getAttribute('data-loaded');
        this.showDelay = parseInt(this.element.getAttribute('data-show'), 10) || 300;
        this.hideDelay = parseInt(this.element.getAttribute('data-hide'), 10) || 100;
        this.cacheable = !this.element.getAttribute('data-nocache');
        this.saveChanges = this.element.getAttribute('data-save-changes');
        this.direction = this.element.getAttribute('data-direction');
        this.position = this.element.getAttribute('data-position') || 'center';
        this.showOnClick = this.element.getAttribute('data-onClick');
        this.isShowImmediately = this.element.getAttribute('data-showImmediately');
        this.showImmediatelySelector = this.element.getAttribute('data-showImmediatelySelector');
        this.relatedMenu = this.getRelatedMenu();
        this.hideOnClick = this.element.getAttribute('data-hideOnClick');
        // Чтобы тело меню sc-menu не исчезало по клику, добавить класс js-doNotHide
        this.hideOnScroll = this.element.getAttribute('data-hideOnScroll');
        this.ignoreDoubleHoverIn = this.element.getAttribute('data-ignore-double-hover-in');
        this.postRequest = this.element.getAttribute("data-postRequest") === '1';

        if (!this.holder) {
            // По какой-то причине нет элемента-триггера
            logger.error('asm', 'noholder');
            logger.success('asm', 'noholder', this.buildParamFingerprint());
            return;
        }

        this.handlers = {};
        this.boundAjaxFail = this.ajaxFail.bind(this);
        this.boundAjaxDone = this.ajaxDone.bind(this);

        this.holderDelegate = new Delegate(this.holder);

        if (OK.device.isTouch) {
            this.holderDelegate.on('touchstart', this.holderTouchStartListener);

            // Если холдер ШМ имеет какое-то отношение к переходу по ссылке, то
            // первый клик должен вызывать только лишь отрисовку ШМ, а второй вызывать переход.
            // Холдер может относиться разным образом: содержать ссылку внутри себя,
            // сам быть ссылкой или быть её потомком
            var a = this.holder.getElementsByTagName('a')[0];
            if (!a) {
                a = traverseParents(this.holder, function (currentElement) {
                    return currentElement.tagName.toUpperCase() === 'A';
                }, document.body, true);
            }

            if (a) {
                // found gwt-bound link
                // bind to capturing event phase
                // a bit hacky, but long tap_in produces link click that cannot be prevented
                // except blocking all touch events
                this.linkDelegate = new Delegate(a);
                this.linkDelegate.on('touchstart', this.touchHandle.bind(this), true);
            }
        }

        var delegateHover = this.element.getAttribute('data-delegate-hover') === '1';

        if (delegateHover) {
            this.holderDelegate.on('mouseleave', '.js-sc-target', this.hoverOutListener.bind(this), true);
        } else {
            this.holderDelegate.on('mouseleave', this.hoverOutListener.bind(this));
        }

        if (this.showOnClick) {
            this.oneClickListener = this.addOneTimeListener(this.holder, 'click', this.showMenu);
        } else {
            if (delegateHover) {
                this.holderDelegate.on('mouseenter', '.js-sc-target', this.hoverInListener.bind(this), true);
            } else {
                this.holderDelegate.on('mouseenter', this.hoverInListener.bind(this));
            }

            if (this.hideOnClick) {
                this.holderDelegate.on('click', this.hoverOutListener.bind(this));
            }
        }

        // Навесить обработчики сразу, если всё уже в дереве, потому что потом не сможем
        if (this.inplace && this.loaded) {
            this.initListeners(this.getBlock());
        }

        if (this.isShowImmediately) {
            this.showImmediately();
        }
    }

    /**
     * Deactivate hook.
     */
    deactivate() {
        if (!this.holder) {
            // По какой-то причине нет элемента-триггера
            return;
        }
        this.ajaxClose();
        this.hide();

        this.removeOneTimeListener(this.oneClickListener);
        this.removeOneTimeListener(this.oneScrollListener);
        this.removeOneTimeListener(this.oneBodyTouchListener);
        this.removeOneTimeListener(this.oneImmediatelyHandler);
        this.holderDelegate.destroy();
        if (this.linkDelegate) {
            this.linkDelegate.destroy();
        }
        if (this.shortcutMenuDelegate) {
            this.shortcutMenuDelegate.destroy();
        }

        this.tmpReactionsActiveInstanceListener = null;
    }

    buildParamFingerprint() {
        var params = [this.inplace, this.loaded, this.cacheable, this.showOnClick, this.hideOnClick, this.hideOnScroll];
        var result = '';

        params.forEach(function (value) {
            result += (!!value * 1).toString();
        });

        return result;
    }

    /**
     * Listener for hover in event. Show shortcut menu after show delay
     */
    hoverInListener(e) {
        if ((this.ignoreDoubleHoverIn && this._hoverIn) || (OK.device.isTouch && e) || (e && parent(e.target, 'js-private-klass', null, true))) {
            return;
        }
        this._hoverIn = true;
        this.clearHideTimeout();
        // не даем ШМ начать показываться, если оно и так видимо
        if (!this.showTimeout && !this.visible()) {
            this.showTimeout = setTimeout(this.showMenu.bind(this), this.showDelay);
        }
    }

    checkAfterTmpReactionsDisabled() {
        if (this._hoverIn) {
            this._hoverIn = false;
            this.hoverInListener();
        }
    }

    /**
     * Listener for hover in event/ Hide shortcut menu after hide delay
     */
    hoverOutListener() {
        this._hoverIn = false;
        this.clearShowTimeout();
        this.ajaxClose();
        // hoverOutListener вызывается при инициализации компонента. Чтобы не исполнять лишнего, проверим на наличие отрисованных элементов
        // Важно проверять, что активный инстанс совпадает с текущим, иначе ШМ может «застревать»
        if (!this.hideTimeout && (activeInstance === this)) {
            this.hideTimeout = setTimeout(this.hideMenu.bind(this), this.hideDelay);
        }
    }

    /**
     * Listener for click event shows shortcut menu immediately without delay
     */
    menuElementHoverInListener() {
        this.menuElementHoverInListenerCalled = true;
        if (this.hideTimeout) {
            this.hideTimeout = clearTimeout(this.hideTimeout);
        }
    }

    /**
     * Listener for click event on links in shortcut menu
     * @param {Event} e event
     */
    menuElementLinkClickListener(e) {
        if (!parent(e.target, 'js-doNotHide', this.block)) {
            this.menuElementClickListener();
        }
    }

    menuElementClickListener() {
        if (true === this.hideTimeout) {
            this.hideTimeout = null;
        }
        this.hoverOutListener();
    }

    /**
     * stops propagation to prevent body touch start listener and make link works on second touch
     * @param {Event} e event
     */
    holderTouchStartListener(e) {
        e.stopPropagation();
    }

    hide() {
        this.clearShowTimeout();
        this.clearHideTimeout();
        if (this.menuElement) {
            this.hideMenu();
        }
    }

    getHolder() {
        var holderId = this.element.getAttribute('data-holder');
        return holderId
            ? document.getElementById(holderId)
            : this.element.previousElementSibling;
    }

    getRelatedMenu() {
        var relatedMenuId = this.element.getAttribute('data-related'), hook;

        if (relatedMenuId) {
            hook = OK.hookModel.getHookById(relatedMenuId);
            return hook.getInstance ? hook.getInstance() : null;
        }
        return null;
    }

    getBlock() {
        if (this.inplace) {
            return this.block;
        }
        return block;
    }

    getHtml() {
        if (!this.block) {
            return ""; //никого нет дома. добавим блок, если нужно будет реально что-то записать.
        }
        var data = this.block.innerHTML;
        if (!this.inplace || !this.cacheable) {
            //Деактивируем все дочерние хуки в теле inplace block но оставляем в нем html
            OK.hookModel.setHookContent(this.blockId, '');
            if (this.cacheable) {
                this.block.innerHTML = data;
            }
        }
        return data;
    }

    initBlock() {
        if (this.element && !this.block) {
            var childrenCollection = Array.prototype.slice.call(this.element.children);
            var find = null;
            for (var collectionIndex = 0; collectionIndex < childrenCollection.length; collectionIndex++) {
                if (childrenCollection[collectionIndex].classList.contains('posR')) {
                    find = childrenCollection[collectionIndex];
                    break;
                }
            }

            if (!find) {
                //вставляем hookBody, если его не было. сразу не надо, что зря не грузить лишний html пользователю.
                var blockElem = document.createElement('div');
                blockElem.id = 'hook_Block_' + this.blockId;
                blockElem.className = 'posR';
                this.element.appendChild(blockElem);

                this.block = blockElem;

                OK.hookModel.captureBlockHook(this.hookId, blockElem);
            } else {
                this.block = find;
            }
        }
    }

    /**
     * Инициализация обработчиков внутри ШМ
     * @param {HTMLElement} listenerBlock
     */
    initListeners(listenerBlock) {
        this.shortcutMenuDelegate = new Delegate(listenerBlock);

        this.shortcutMenuDelegate.on('click', '.sc-menu a', this.menuElementLinkClickListener.bind(this));

        // Закрыть попап с таймаутом
        this.shortcutMenuDelegate.on('click', '.sc-menu .js-hide', this.menuElementClickListener.bind(this));

        // Закрыть попап мгновенно
        this.shortcutMenuDelegate.on('click', '.js-forceHide', this.hideMenu.bind(this));

        this.shortcutMenuDelegate.on('mouseenter', this.menuElementHoverInListener.bind(this));

        this.shortcutMenuDelegate.on('mouseleave', this.hoverOutListener.bind(this));

        if (OK.device.isTouch) {
            this.shortcutMenuDelegate.on('touchstart', this.holderTouchStartListener.bind(this));
        }
    }

    /**
     * Make ajax request to 'data-url' attribute and then show shortcut menu
     */
    showMenu() {
        clearTimeout(this.hideTimeout);
        this.showTimeout = null;

        // для реакций, выпилить после экспов
        if (this.tmpReactionsDisabled) {
            return;
        }

        // Если связанное меню вызвано на отрисовку ховером по якорю, оно будет отрисовано с таймаутом.
        // Если текущее меню вызвано кликом, оно будет отрисовано мгновенно.
        // Таким образом, связанное меню может закрывать собой текущее, если клик и ховер будут почти одновременны
        // Эта ситуация происходит, например, при автотестах
        // При этом связанное меню не будет доступно в activeInstance, т.к. отрисовка еще не произошла
        if (this.relatedMenu && this.relatedMenu.hide) {
            this.relatedMenu.hide();
        }

        if (activeInstance) {
            //Точно такое же меню от этого элемента уже показано. Не надо ничего делать
            if (this.visible()) {
                return;
            }

            // Не должно быть открыто больше 1 ШМ
            activeInstance.hide();
        }

        if (this.showOnClick) {
            this.removeOneTimeListener(this.oneClickListener);
            this.oneClickListener = this.addOneTimeListener(this.holder, 'click', this.hideMenu);
        }

        if (this.hideOnScroll) {
            var overflowRegex = /(auto|scroll)/;
            var closestScrollable = traverseParents(this.element, function (currentElement) {
                var style = window.getComputedStyle(currentElement);
                return (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) && (currentElement.scrollHeight > currentElement.clientHeight);
            });

            closestScrollable = closestScrollable || window;

            this.oneScrollListener = this.addOneTimeListener(closestScrollable, 'scroll', this.hoverOutListener);
        }

        this.showTimeout = null;
        if (OK.device.isTouch) {
            this.oneBodyTouchListener = this.addOneTimeListener(document.body, 'touchstart', this.hoverOutListener);
        }

        if (this.ajaxRequest) {
            return;
        }

        this.startTime = Date.now();

        if (this.loaded) {
            var html = this.getHtml();
            this.processHTML(html, undefined, !this.inplace || !this.cacheable);
            logger.success('asm', 'show', 'cache');
        } else {
            var url = this.element.getAttribute('data-url');

            if (this.postRequest) {
                const postData = {};
                const requestUrl = prepareForPost(url, postData);
                // передаю function(){}, чтобы отключить редиректы когда лайкеры недоступны по приватности
                this.ajaxRequest = ajax({
                    url: requestUrl,
                    type: 'POST',
                    data: postData,
                    dataType: 'html'
                }, function () {
                });
            } else {
                // передаю function(){}, чтобы отключить редиректы когда лайкеры недоступны по приватности
                this.ajaxRequest = ajax({
                    url: url,
                    dataType: 'html'
                }, function () {
                });
            }
            this.ajaxRequest.then(
                this.boundAjaxDone,
                this.boundAjaxFail
            );
        }
    }

    /**
     * Отображаем ШМ как только отрендерились.
     */
    showImmediately() {
        var self = this;
        if (self.showImmediatelySelector) {
            // Задержка, чтобы хуки в меню активировались
            setTimeout(function () {
                // Показываем ШМ, только если мышку далеко не увели.
                var closestSelectorName = self.showImmediatelySelector + ':hover';
                self.showImmediatelyElement = traverseParents(self.holder, function (element) {
                    return element.matches(closestSelectorName);
                });

                // Можем дойти до корня, это нехорошо
                if (self.showImmediatelyElement.matches(closestSelectorName)) {
                    self.showMenu();
                    // Скрываем ШМ, если сразу уводим мышку далеко.
                    // Если не сделать - меню может никогда не скрыться.
                    self.oneImmediatelyHandler = this.addOneTimeListener(self.showImmediatelyElement, 'mouseleave', function () {
                        setTimeout(function () {
                            if (!self.menuElementHoverInListenerCalled) {
                                self.hideMenu();
                            }
                        }, self.hideDelay);
                    });
                }
            }, 1);
        }
    }

    visible() {
        return activeInstance === this;
    }

    /**
     * Hide shortcut menu
     */
    hideMenu() {

        // блокируем таймаут показа меню, т.к. уже показывать меню не надо
        this.clearShowTimeout();

        // Может быть видимым только 1 экземпляр ШМ.
        // Игнорируем кейс, когда скрыться пытается элемент, который не был отрисован (быстрый провод мышью по якорю)
        // Тут могут быть проблемы, если есть связанное меню — в этом случае требование видимости одного экземпляра может быть нарушено
        // Сейчас связанное меню может быть только по клику, для решения добавлена проверка по этому признаку
        if (!this.visible() && !this.showOnClick) {
            return;
        }

        this.hideTimeout = null;
        this.stopClick = true;

        if (this.showOnClick) {
            this.removeOneTimeListener(this.oneClickListener);
            this.oneClickListener = this.addOneTimeListener(this.holder, 'click', this.showMenu);
        }

        this.ajaxClose(function () {
            logger.success('asm', 'ajax', 'to');
        });

        if (this.menuElement) {
            this.menuElement.classList.add('sc-menu__hidden');
            this.getHtml();
            if (this.saveChanges && !this.inplace && this.loaded && this.cacheable) {
                // Для случая, если в ШМ может меняться состояние (html),
                // сохраняем измененный html для следующего показа ШМ
                this.initBlock();
                this.block.innerHTML = block.innerHTML;
            }
            OK.hookModel.setHookContent(blockId, '');
            this.menuElement = null;
        }
        this.holder.classList.remove('__vis');
        if (this.shortcutMenuDelegate && !this.cacheable) {
            // Отписка от событий внутри ШМ
            this.shortcutMenuDelegate.off();
        }

        activeInstance = null;
        // для реакций, выпилить после экспов
        this.tmpReactionsActiveInstanceListener && this.tmpReactionsActiveInstanceListener();
    }

    /**
     * Listener that will catch first touch on element and ignore it
     * Second click will close menu
     * @param {Event} e event
     * @return {?boolean}
     */
    touchHandle(e) {
        if (this.stopClick) {
            e.preventDefault();
            e.stopPropagation();
            this.stopClick = false;
            if (!this.showOnClick) {
                this.hoverInListener();
            }
            return false;
        } else {
            this.hoverOutListener();
        }
    }

    getOffset(element) {
        var rect = element.getBoundingClientRect();
        return {
            left: rect.left + window.pageXOffset,
            top: rect.top + window.pageYOffset
        };
    }

    /**
     * Sets shortcut menu position up
     */
    positingElement() {
        var parentHeight = outerSize(this.holder, false, true);
        //Наш новый блок должен быть position: relative
        var parentOffset = this.inplace
            ? {top: 0, left: 0}
            : this.getOffset(this.holder);

        var parentWidth = outerSize(this.holder, true, true);
        var menuElementWidth = outerSize(this.menuElement, true, true);

        // Удалить лишний отступ для обсчета геометрии
        if (this.position !== 'center' && this.position !== 'auto') {
            this.menuElement.classList.add('__noarrow');
        }

        var topDirection;
        var menuElementHeight = outerSize(this.menuElement, false, true);

        if (this.direction === 'top') { // приоритет на отрисовку вверх
            topDirection = this.getOffset(this.holder).top - window.pageYOffset - 90 > menuElementHeight;
        } else if (this.direction === 'bottom') { // приоритет на отрисовку вниз
            topDirection = false;
        } else {
            topDirection = window.innerHeight - parentHeight - this.holder.getBoundingClientRect().top < menuElementHeight;
        }

        // display below (default) or above target, if below is not enough of space
        // but don't overlap toolbar
        var top = parentOffset.top;
        var styleRules = [];

        if (topDirection) {
            this.menuElement.classList.add("sc-menu__top");
            var bottom;
            if (this.inplace) {
                bottom = top + parentHeight;
                //Позиционируем по bottom чтобы в случае, когда размеры меню неявно меняются оно уезжало в нужном направлении
                styleRules.push('top: auto; bottom: ' + Math.round(bottom) + 'px;');
            } else {
                top -= menuElementHeight;
                styleRules.push('bottom: auto; top: ' + Math.round(top) + 'px;');
            }
        } else {
            this.menuElement.classList.remove("sc-menu__top");
            if (!this.inplace) {
                top += parentHeight;
            }
            styleRules.push('bottom: auto; top: ' + Math.round(top) + 'px;');
        }

        var leftCoords = Math.round(parentOffset.left - (menuElementWidth - parentWidth));
        var rightCoords = Math.round(parentOffset.left);
        var centerCoords = Math.round(parentOffset.left + ((parentWidth - menuElementWidth) / 2));

        switch (this.position) {
            case 'left':
                styleRules.push('left: ' + leftCoords + 'px;');
                break;

            case 'right':
                styleRules.push('left: ' + rightCoords + 'px;');
                break;

            case 'auto':
                if ((window.innerWidth - OK.scrollBar.width) > (parentOffset.left + menuElementWidth)) {
                    styleRules.push('left: ' + rightCoords + 'px;');
                    this.menuElement.classList.remove('__right');
                    this.menuElement.classList.add('__left');
                } else {
                    styleRules.push('left: ' + leftCoords + 'px;');
                    this.menuElement.classList.add('__right');
                    this.menuElement.classList.remove('__left');
                }
                break;

            default:
                styleRules.push('left: ' + centerCoords + 'px;');
                break;
        }

        this.menuElement.setAttribute('style', styleRules.join(""));
    }

    /**
     * Appends HTML to DOM and sets up position
     * @param {string} html of menu (XHR result or saved copy)
     * @param {XMLHttpRequest} xhr
     * @param {boolean} isUpdateBlock
     */
    processHTML(html, xhr, isUpdateBlock) {
        var blockIds = [];
        if (xhr && xhr.getResponseHeader(OK.navigation.HEADER)) {
            blockIds = xhr.getResponseHeader(OK.navigation.HEADER).split(",");
        }

        var b;
        if (this.inplace) {
            this.initBlock();
            blockIds[0] = this.blockId;
            b = this.block;
        } else {
            blockIds[0] = blockId;
            b = block;
        }

        if (isUpdateBlock) {
            updateBlocks(html, blockIds);
            // Будем вешать листенеры на содержимое меню только при его вставке в дерево
            this.initListeners(this.getBlock());
        }

        this.menuElement = firstByClass(b, 'sc-menu');
        this.positingElement();

        this.menuElement.classList.remove('sc-menu__hidden');
        this.holder.classList.add('__vis');
        activeInstance = this;
        // для реакций, выпилить после экспов
        this.tmpReactionsActiveInstanceListener && this.tmpReactionsActiveInstanceListener();
    }

    /**
     * Function that will be called on ajax done
     * @param {!Object} result ajax response
     */
    ajaxDone(result) {
        logger.duration('asm', Date.now() - this.startTime, 'ajax');
        var data = result.response;

        // Видимо, нас интересуют только непустые ответные строки
        if (!data || (data.split(OK.navigation.SPLITER)[0]).length === 0) {
            logger.success('asm', 'ajax', 'e');
            return;
        }
        logger.success('asm', 'show', 'ajax');

        if (this.cacheable && this.element) { // prevent saving html after module deactivation
            this.loaded = true;
            this.initBlock();
            this.block.innerHTML = data.split(OK.navigation.SPLITER)[0];
        }
        this.processHTML(data, result.xhr, true);

        this.menuShownListener && this.menuShownListener();

        this.ajaxClose();
    }

    /**
     * Function that will be called on ajax fail
     */
    ajaxFail() {
        this.ajaxClose(function () {
            logger.error('asm', 'ajax');
        });
    }

    /**
     * При наличии активного сетевого запроса закрываем его
     *
     * @param cb
     */
    ajaxClose(cb) {
        if (this.ajaxRequest) {
            this.ajaxRequest.xhr.abort();
            this.ajaxRequest = null;

            if (typeof cb === 'function') {
                cb();
            }
        }
    }

    isWaitingBeforeShow() {
        return this.showTimeout || this.ajaxRequest;
    }

    abortWaitingBeforeShow() {
        // отменяем таймаут показа
        this.clearShowTimeout();
        // если таймаут уже случился и идёт сетевой запрос, отменяем его
        this.ajaxClose();
    }

    clearShowTimeout() {
        if (this.showTimeout) {
            this.showTimeout = clearTimeout(this.showTimeout); // if menu is not opened yet, prevent opening
        }
    }

    clearHideTimeout() {
        if (this.hideTimeout) {
            this.hideTimeout = clearTimeout(this.hideTimeout); // if menu is not closed yet, prevent closing
        }
    }

    addOneTimeListener(element, type, handler) {
        var that = this;
        var oneTimeHandler = function oneTimeHandler(e) {
            element.removeEventListener(e.type, oneTimeHandler);
            handler.call(that, e);
        };
        element.addEventListener(type, oneTimeHandler);

        return {
            element: element,
            type: type,
            handler: oneTimeHandler
        };
    }

    removeOneTimeListener(listenerObject) {
        if (listenerObject) {
            listenerObject.element.removeEventListener(listenerObject.type, listenerObject.handler);
            listenerObject = null;
        }
    }
}


export default ShortcutMenuReact;

var getActive = ShortcutMenuReact.getActive;
var setReactComponent = ShortcutMenuReact.setReactComponent;
var removeReactComponent = ShortcutMenuReact.removeReactComponent;
var getReactComponent = ShortcutMenuReact.getReactComponent;
export { getActive, setReactComponent, removeReactComponent, getReactComponent };
